import { AuthFacade, CompaniesFacade, UserAccountsFacade } from '@account/core/facades';
import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { delay, tap } from 'rxjs/operators';

import { UserAccount } from '../../../core/models/user/user-account.model';
import { AuthCardComponent } from '../../../shared/components/auth-card/auth-card.component';
import { StatusComponent } from '../../../shared/components/status/status.component';
import { SwIconComponent } from '../../../shared/components/sw-icon/sw-icon.component';

@Component({
  selector: 'account-verification',
  templateUrl: './verification.component.html',
  styleUrl: './verification.component.less',
  standalone: true,
  imports: [NgIf, RouterLink, TranslateModule, StatusComponent, SwIconComponent, AuthCardComponent],
})
export class VerificationComponent implements OnInit {
  success: boolean;

  constructor(
    private readonly authFacade: AuthFacade,
    private readonly companiesFacade: CompaniesFacade,
    private readonly userAccountsFacade: UserAccountsFacade,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    const verification = {
      token: this.activatedRoute.snapshot.queryParams.token,
      locale: this.activatedRoute.snapshot.queryParams.lang,
    };

    if (!verification.token) {
      this.router.navigate(['front']);
      return;
    }

    this.userAccountsFacade
      .verify(verification)
      .pipe(
        tap(() => (this.success = true)),
        delay(3000)
      )
      .subscribe(
        (verificationResult: { url: string } | UserAccount) => {
          const params: any = {};
          if (this.isUserAccountVerificationResult(verificationResult)) {
            params.email = verificationResult['email'];
          }
          this.authFacade.setRedirectUrl('/');
          this.router.navigate(['front'], { queryParams: params });
        },
        () => {
          this.success = false;
        }
      );
  }

  private isUserAccountVerificationResult(
    verificationResult: { url: string } | UserAccount
  ): verificationResult is UserAccount {
    return Object.prototype.hasOwnProperty.call(verificationResult, 'email');
  }
}
