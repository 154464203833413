import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SbpTableContainerIntl } from '../../shared/components/table-container/table-container-intl.service';

@Injectable({
  providedIn: 'root',
})
export class AccountTableContainerIntl extends SbpTableContainerIntl {
  constructor(private readonly translateService: TranslateService) {
    super();
    this.setTranslationLabels();
    this.translateService.onLangChange.subscribe(() => {
      this.setTranslationLabels();
    });
  }

  private setTranslationLabels(): void {
    this.ofLabel = this.translateService.instant('COMMON.TABLE.OF');
    this.entriesLabel = this.translateService.instant('COMMON.TABLE.ENTRIES');
    this.searchLabel = this.translateService.instant('COMMON.TABLE.SEARCH');
    this.resetFilterLabel = this.translateService.instant('COMMON.TABLE.RESET_FILTER');
    this.applyFilterLabel = this.translateService.instant('COMMON.TABLE.APPLY_FILTER');
  }
}
