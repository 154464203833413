import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { StatusComponent } from '../../../shared/components/status/status.component';
import { SwIconComponent } from '../../../shared/components/sw-icon/sw-icon.component';

@Component({
  selector: 'account-register-completed',
  templateUrl: './register-completed.component.html',
  styleUrl: './register-completed.component.less',
  standalone: true,
  imports: [RouterLink, TranslateModule, StatusComponent, SwIconComponent],
})
export class RegisterCompletedComponent {}
