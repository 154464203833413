import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, take } from 'rxjs/operators';

import { UserAccountsGateway } from '../../../gateways/auth';
import { CompanyMembershipGateway } from '../../../gateways/company/company-membership.gateway';
import { CompanyMembership, CompanyMembershipInvitation, SbpException } from '../../../models';
import { TokenService } from '../../../services';
import * as companyActions from '../../company/company/company.actions';
import * as signedGTCActions from '../../company/signed-gtc/signed-gtc.actions';
import * as navigationActions from '../../navigation/navigation.actions';
import { RootState } from '../../root.state';
import * as membershipsActions from '../memberships/memberships.actions';
import * as membershipsSelectors from '../memberships/memberships.selectors';
import * as permissionActions from '../permissions/permissions.actions';
import * as userAccountActions from '../useraccount/useraccount.actions';
import * as actions from './invitations.actions';

@Injectable({
  providedIn: 'root',
})
export class InvitationsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly tokenService: TokenService,
    private readonly userAccountsGateway: UserAccountsGateway,
    private readonly companyMembershipGateway: CompanyMembershipGateway
  ) {}

  acceptInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.acceptInvitation),
      map((action: { payload: CompanyMembershipInvitation }) => action.payload),
      switchMap((invitation: CompanyMembershipInvitation) =>
        this.companyMembershipGateway.acceptMembershipInvitation(invitation).pipe(
          switchMap((membership: CompanyMembership) =>
            this.store.select(membershipsSelectors.getMemberships).pipe(
              take(1),
              concatMap((memberships: CompanyMembership[]) => [
                navigationActions.resetNavigation(),
                navigationActions.setNavigationForUserAccount(),
                signedGTCActions.refreshSignedGTC(),
                actions.acceptInvitationSuccess({ payload: invitation }),
                membershipsActions.getMembershipsSuccess({ payload: [...memberships, membership] }),
                userAccountActions.changeSelectedMembership({ payload: membership }),
                companyActions.getCompany({ payload: membership.company.id }),
                permissionActions.setPermissions({ payload: membership }),
              ])
            )
          ),
          catchError((error: SbpException) => of(actions.acceptInvitationFail({ payload: error })))
        )
      )
    )
  );

  getInvitations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getInvitations),
      map((action) => action.payload),
      switchMap((userAccountId: number) =>
        this.userAccountsGateway.getMembershipInvitations(userAccountId).pipe(
          map((invitations: CompanyMembershipInvitation[]) => actions.getInvitationsSuccess({ payload: invitations })),
          catchError((error: SbpException) => of(actions.getInvitationsFail({ payload: error })))
        )
      )
    )
  );

  declineInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.declineInvitation),
      map((action: { payload: CompanyMembershipInvitation }) => action.payload),
      switchMap((invitation: CompanyMembershipInvitation) =>
        this.companyMembershipGateway.declineMembershipInvitation(invitation).pipe(
          map((invitation: CompanyMembershipInvitation) => actions.declineInvitationSuccess({ payload: invitation })),
          catchError((error: SbpException) => of(actions.declineInvitationFail({ payload: error })))
        )
      )
    )
  );
}
