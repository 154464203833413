import { AclFacade, BasketFacade, CompaniesFacade } from '@account/core/facades';
import { Basket } from '@account/core/models';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, switchMap, switchMapTo, take } from 'rxjs/operators';

import * as actions from './basket.actions';

@Injectable({
  providedIn: 'root',
})
export class BasketEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly companiesFacade: CompaniesFacade,
    private readonly basketFacade: BasketFacade,
    private readonly aclFacade: AclFacade
  ) {}

  setBasketForCompanyUserAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setBasketForCompanyUserAccount),
      // take current state once otherwise observable will not trigger correctly up on logging out and back in
      switchMapTo(this.companiesFacade.hasCompany().pipe(take(1))),
      switchMap((hasCompany: boolean) => {
        if (!hasCompany) {
          return EMPTY;
        }

        return this.aclFacade.isAccessGrantedForPermission(['academy_certifications']);
      }),
      switchMap((hasPermission: boolean) => {
        if (!hasPermission) {
          return EMPTY;
        }

        return this.basketFacade.getBasket();
      }),
      map((basket: Basket | null) =>
        actions.setBasketForCompanyUserAccountSuccess({
          payload: { basket },
        })
      )
    )
  );
}
