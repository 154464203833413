import { CompaniesFacade } from '@account/core/facades';
import { CompanyAllocations } from '@account/core/models';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MigrationGuard {
  constructor(
    private readonly router: Router,
    private readonly companiesFacade: CompaniesFacade
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const migrationCampaignRedirectParam = route.queryParams.campaign;
    if (!migrationCampaignRedirectParam) {
      return of(true);
    }
    return this.companiesFacade.hasCompany().pipe(
      switchMap((hasCompany: boolean) => {
        if (!hasCompany) {
          return of(true);
        }

        return this.companiesFacade.getAllocations().pipe(
          map((allocations: CompanyAllocations) => {
            const pricingTeasers = allocations.pricingTeaser;

            for (const pricingTeaser of pricingTeasers) {
              switch (migrationCampaignRedirectParam) {
                case 'sw5_migration':
                  if (pricingTeaser.shop.shopwareVersion.major === 'Shopware 5') {
                    return this.router.parseUrl(`shops/shops/${pricingTeaser.shop.id}?campaign=sw5_migration`);
                  }
                  break;
                case 'sw6_upgrade':
                  if (
                    pricingTeaser.shop.shopwareVersion.major === 'Shopware 6' &&
                    !pricingTeaser.shop.subscriptionModules
                  ) {
                    return this.router.parseUrl(`shops/shops/${pricingTeaser.shop.id}?campaign=sw6_upgrade`);
                  }
                  break;
                case 'migration_or_upgrade':
                  return this.router.parseUrl(`shops/shops/${pricingTeaser.shop.id}?campaign=migration_or_upgrade`);
              }
            }
            return true;
          })
        );
      })
    );
  }
}
