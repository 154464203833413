import { EnvironmentService, FeatureFlagService, OrySessionService } from '@account/core/services';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthServiceRedirectGuard implements CanActivate, CanActivateChild {
  constructor(
    private readonly featureFlagService: FeatureFlagService,
    private readonly environmentService: EnvironmentService,
    private readonly orySessionService: OrySessionService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    if (!this.featureFlagService.flagActivated('ory')) {
      return of(true);
    }

    const lastUrlSegment = route.url.at(-1);
    const authUiPath = this.getAuthUiPath(lastUrlSegment.path);
    if (!authUiPath) {
      return of(true);
    }

    const basePath = this.environmentService.current.auth_ui_url;
    const queryParams = this.getQueryParams(lastUrlSegment.path);
    window.location.href = `${basePath}${authUiPath}?${queryParams}`;

    return of(false);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.canActivate(childRoute);
  }

  private getAuthUiPath(lastUrlSegmentPath: string): string | null {
    const map: Record<string, string> = {
      register: '/registration',
      verify: '/verification',
      verifyaccount: '/verification',
      recovery: '/recovery',
      confirmPassword: '/recovery',
      'password-reset': '/recovery',
    };

    return map[lastUrlSegmentPath] ?? null;
  }

  private getQueryParams(lastUrlSegmentPath: string): URLSearchParams {
    const params: Record<string, string> = {
      return_to: `${window.location.protocol}//${window.location.host}`,
    };
    if (['verify', 'verifyaccount'].includes(lastUrlSegmentPath)) {
      if (this.orySessionService.getEmail()) {
        params['email'] = this.orySessionService.getEmail();
      }
    }

    return new URLSearchParams(params);
  }
}
