import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';

import { CompaniesGateway } from '../../../gateways/company/companies.gateway';
import { SbpException } from '../../../models/api/sbp-exception.model';
import { CompanyAllocations } from '../../../models/company/company-allocations.model';
import * as navigationActions from '../../navigation/navigation.actions';
import { RootState } from '../../root.state';
import * as sessionActions from '../../session/session.actions';
import * as actions from './allocations.actions';

@Injectable({
  providedIn: 'root',
})
export class AllocationsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly companiesGateway: CompaniesGateway
  ) {}

  getCompanyAllocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getCompanyAllocations),
      map((action) => action.payload),
      switchMap((companyId: number) =>
        this.companiesGateway.getAllocations(companyId).pipe(
          concatMap((companyAllocations: CompanyAllocations) => [
            sessionActions.setPartnerId({
              payload: companyAllocations.isPartner ? companyAllocations.partnerId : undefined,
            }),
            sessionActions.setProducerId({
              payload: companyAllocations.isProducer ? companyAllocations.producerId : undefined,
            }),
            actions.getCompanyAllocationsSuccess({ payload: companyAllocations }),
          ]),
          catchError((error: SbpException) => of(actions.getCompanyAllocationsFail({ payload: error })))
        )
      )
    )
  );

  setCompanyAllocationsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getCompanyAllocationsSuccess),
      map(() => navigationActions.setNavigationForCompany())
    )
  );
}
