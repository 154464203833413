import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';

import { LogoutGuard } from '../../core';
import { AuthServiceRedirectGuard } from '../../core/guards/auth-service-redirect.guard';
import { UserAccountRegisterFormComponent } from './user-account-register-form/user-account-register-form.component';
import { VerificationComponent } from './verification/verification.component';

const canActivateLogout: CanActivateFn | CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => inject(LogoutGuard).canActivate(route, state);

const canAuthServiceRedirect: CanActivateFn | CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
) => inject(AuthServiceRedirectGuard).canActivate(route);

export const registerRoutes: Routes = [
  {
    path: 'register',
    component: UserAccountRegisterFormComponent,
    canActivate: [canActivateLogout, canAuthServiceRedirect],
  },
  {
    path: 'verify',
    component: VerificationComponent,
    canActivate: [canActivateLogout, canAuthServiceRedirect],
  },
  {
    path: 'verifyaccount',
    component: VerificationComponent,
    canActivate: [canActivateLogout, canAuthServiceRedirect],
  },
];
