<account-auth-card *ngIf="loaded$ | async" [loading]="isRegistering">
  <div *ngIf="completed === false; else registrationCompleted">
    <a routerLink="/front" class="link-back">
      <account-sw-icon icon="chevron-left-s" size="16px"></account-sw-icon>
      {{ 'FRONT.BACK_TO_LOGIN' | translate }}
    </a>
    <div class="form-wrapper">
      <strong class="headline">{{ 'FRONT.REGISTER.USER.HEADLINE' | translate }}</strong>
      <p class="information" *ngIf="form.get('email').disabled">{{ 'FRONT.REGISTER.USER.INFO_TEXT' | translate }}</p>
      <form [formGroup]="form">
        <div class="a_column-12">
          <div
            class="a_control is_required"
            [class.is_error]="form.get('email').dirty && form.get('email').errors?.email">
            <div class="control_description">
              <strong>{{ 'COMMON.EMAIL' | translate }}</strong>
            </div>
            <div class="control_content">
              <input type="email" name="email" formControlName="email" autocomplete="username" />
            </div>
            <div class="control_help" *ngIf="form.get('email').dirty && form.get('email').errors?.email">
              {{ 'COMMON.REGISTER.ERROR.EMAIL_INVALID' | translate }}
            </div>
          </div>
        </div>
        <account-password-mask
          class="a_column-12"
          [formControl]="form.get('password')"
          [errors]="form.get('password').errors"></account-password-mask>
        <div class="a_column-12">
          <div
            class="a_control is_required"
            [class.is_error]="form.get('passwordRepeat').dirty && form.errors?.passwordMismatch">
            <div class="control_description">
              <strong>{{ 'FRONT.REGISTER.REPEAT_PASSWORD' | translate }}</strong>
            </div>
            <div class="control_content">
              <input
                type="password"
                name="password"
                formControlName="passwordRepeat"
                autocomplete="new-password"
                (capsLockToggled)="isCapsLockOn = $event" />
            </div>
            <div class="caps_lock_warning" *ngIf="isCapsLockOn">
              {{ 'FRONT.LOGIN.CAPS_LOCK_WARNING' | translate }}
            </div>
            <div
              class="control_help help_register"
              *ngIf="form.get('passwordRepeat').dirty && form.errors?.passwordMismatch">
              {{ 'COMMON.REGISTER.ERROR.PASSWORD_MISMATCH' | translate }}
            </div>
          </div>
        </div>
        <div class="a_column-12">
          <div
            class="a_control is_required"
            [class.is_error]="form.get('salutation').dirty && form.get('salutation').invalid">
            <div class="control_description">
              <strong>{{ 'COMMON.SALUTATION.TITLE' | translate }}</strong>
            </div>
            <div class="control_content">
              <div class="a_select">
                <select formControlName="salutation">
                  <option selected disabled [value]="null"></option>
                  <option value="M">{{ 'COMMON.SALUTATION.MALE' | translate }}</option>
                  <option value="F">{{ 'COMMON.SALUTATION.FEMALE' | translate }}</option>
                  <option value="O">{{ 'COMMON.SALUTATION.OTHER' | translate }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="a_column-12">
          <div
            class="a_control is_required"
            [class.is_error]="form.get('firstName').dirty && form.get('firstName').invalid">
            <div class="control_description">
              <strong>{{ 'COMMON.FIRSTNAME' | translate }}</strong>
            </div>
            <div class="control_content">
              <input formControlName="firstName" type="text" name="firstName" />
            </div>
          </div>
        </div>
        <div class="a_column-12">
          <div
            class="a_control is_required"
            [class.is_error]="form.get('lastName').dirty && form.get('lastName').invalid">
            <div class="control_description">
              <strong>{{ 'COMMON.LASTNAME' | translate }}</strong>
            </div>
            <div class="control_content">
              <input formControlName="lastName" type="text" name="lastName" />
            </div>
          </div>
        </div>
        <div class="a_column-12">
          <div class="a_control is_required">
            <div class="control_description">
              <strong>{{ 'COMMON.LABEL.LANGUAGE' | translate }}</strong>
            </div>
            <div class="control_content">
              <div class="a_select">
                <select formControlName="locale">
                  <option selected disabled [value]="null"></option>
                  <option *ngFor="let locale of locales$ | async" [ngValue]="locale">
                    {{ 'LANGUAGE.' + locale.name | translate }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="a_column-12">
          <div class="a_control">
            <div class="control_content">
              <account-checkbox
                formControlName="newsletterSubscription"
                [label]="'FRONT.REGISTER.NEWSLETTER_SUBSCRIPTION' | translate"></account-checkbox>
            </div>
          </div>
        </div>
        <div class="a_column-12">
          <div class="a_control is_required">
            <div class="control_description">
              <account-checkbox
                formControlName="privacyPolicy"
                [label]="'FRONT.REGISTER.PRIVACY_POLICY' | translate"
                [labelAsHTML]="true"></account-checkbox>
            </div>
            <div class="control_help">
              <span [innerHTML]="'COMMON.CAPTCHA_HELPTEXT' | translate | safe: 'html'"></span>
            </div>
          </div>
        </div>
        <button
          class="a_button is_primary"
          type="button"
          data-float-right
          [disabled]="form.invalid"
          [class.is_disabled]="form.invalid"
          (click)="submit()">
          {{ 'FRONT.REGISTER.REGISTER_BUTTON' | translate }}
        </button>
      </form>
    </div>
  </div>
</account-auth-card>

<ng-template #registrationCompleted>
  <account-register-completed></account-register-completed>
</ng-template>
