import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as uuid from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class CorrelationIdInterceptor implements HttpInterceptor {
  private readonly correlationId: string = uuid.v4().toString();

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request = req.clone({
      headers: req.headers.set('X-Shopware-Correlation-ID', this.correlationId),
    });

    return next.handle(request);
  }
}
