import { AuthFacade } from '@account/core/facades';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { interval, merge, of } from 'rxjs';
import { catchError, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { CompaniesGateway } from '../../../gateways/company/companies.gateway';
import { SbpException, SupportTicketUpdates } from '../../../models';
import { RootState } from '../../root.state';
import * as sessionActions from '../../session/session.actions';
import * as userAccountActions from '../../useraccount/useraccount-meta.actions';
import * as actions from './support-ticket-updates.actions';

@Injectable({
  providedIn: 'root',
})
export class SupportTicketUpdatesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly authFacade: AuthFacade,
    private readonly companiesGateway: CompaniesGateway
  ) {}

  getSupportTicketUpdates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getSupportTicketUpdates),
      map((action) => action.payload),
      switchMap((companyId: number) => {
        const changeCompany$ = this.actions$.pipe(ofType(userAccountActions.changeSelectedMembership));
        const logout$ = this.actions$.pipe(ofType(sessionActions.logout));
        // update every 5 min
        return interval(300000).pipe(
          // start immediately
          startWith(0),
          // stop updating support-ticket updates if company change or logout is triggered
          takeUntil(merge(changeCompany$, logout$)),
          switchMap(() =>
            this.companiesGateway.getSupportTicketUpdates(companyId).pipe(
              map((supportTicketUpdates: SupportTicketUpdates) =>
                actions.getSupportTicketUpdatesSuccess({ payload: supportTicketUpdates })
              ),
              catchError((error: SbpException) => of(actions.getSupportTicketUpdatesFail({ payload: error })))
            )
          ),
          takeUntil(this.authFacade.waitUntilLoggingOut())
        );
      })
    )
  );

  refreshSupportTicketUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.refreshSupportTicketUpdate),
      map((action) => action.payload),
      switchMap((companyId: number) =>
        this.companiesGateway.getSupportTicketUpdates(companyId).pipe(
          map((supportTicketUpdates: SupportTicketUpdates) =>
            actions.getSupportTicketUpdatesSuccess({ payload: supportTicketUpdates })
          ),
          catchError((error: SbpException) => of(actions.getSupportTicketUpdatesFail({ payload: error })))
        )
      )
    )
  );
}
