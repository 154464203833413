import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';

import { AuthServiceRedirectGuard } from '../../core/guards/auth-service-redirect.guard';
import { LogoutGuard } from '../../core/guards/logout.guard';

const canActivateLogout: CanActivateFn | CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => inject(LogoutGuard).canActivate(route, state);

const canAuthServiceRedirect: CanActivateFn | CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
) => inject(AuthServiceRedirectGuard).canActivate(route);

export const frontRoutes: Routes = [
  {
    path: 'front',
    canActivate: [canActivateLogout],
    loadComponent: () => import('./front.component').then((c) => c.FrontComponent),
  },
  {
    path: 'recovery',
    canActivate: [canActivateLogout, canAuthServiceRedirect],
    loadComponent: () => import('./password-reset/forgot-password.component').then((c) => c.ForgotPasswordComponent),
  },
  {
    path: 'confirmPassword',
    canActivate: [canActivateLogout, canAuthServiceRedirect],
    loadComponent: () =>
      import('./password-reset/company/reset-password.component').then((c) => c.ResetPasswordComponent),
  },
  {
    path: 'password-reset',
    canActivate: [canActivateLogout, canAuthServiceRedirect],
    loadComponent: () =>
      import('./password-reset/user-account/reset-password.component').then((c) => c.UserAccountResetPasswordComponent),
  },
];
