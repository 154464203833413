<a routerLink="/front" class="link-back">
  <account-sw-icon icon="chevron-left-s" size="16px"></account-sw-icon>
  {{ 'FRONT.BACK_TO_LOGIN' | translate }}
</a>

<div class="content-container">
  <account-status type="success" [large]="true"></account-status>
  <strong>{{ 'FRONT.REGISTER.CONFIRM_EMAIL' | translate }}</strong>
  <p>{{ 'FRONT.REGISTER.COMPLETED' | translate }}</p>
</div>
