import { AclFacade, CompaniesFacade } from '@account/core/facades';
import { CompanyAllocations, SupportTicketUpdates, Workspace, WorkspaceItem } from '@account/core/models';
import { EnvironmentService, FeatureFlagService } from '@account/core/services';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { map, mapTo, switchMap, switchMapTo, take } from 'rxjs/operators';

import {
  academyWorkspace,
  companyWorkspace,
  partnerWorkspace,
  producerWorkspace,
  shopOwnerWorkspace,
  userAccountWorkspace,
  workspaces,
} from '../../components/navigation/workspaces';
import { RootState } from '../root.state';
import * as actions from './navigation.actions';
import * as selectors from './navigation.selectors';
import { NavigationState } from './navigation.state';

@Injectable({
  providedIn: 'root',
})
export class NavigationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly aclFacade: AclFacade,
    private readonly companiesFacade: CompaniesFacade,
    private readonly featureFlagService: FeatureFlagService,
    private readonly environmentService: EnvironmentService
  ) {}

  setNavigationForUserAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setNavigationForUserAccount),
      // take current state once otherwise observable will not trigger correctly up on logging out and back in
      switchMapTo(this.companiesFacade.hasCompany().pipe(take(1))),
      map((hasCompany: boolean) => {
        if (this.featureFlagService.flagActivated('ory')) {
          const externalSettingsWorkspaceItems = userAccountWorkspace.find(
            (item: WorkspaceItem) => item.path === '/settings/externalmyaccount'
          );
          const newExternalLink = `${this.environmentService.current.auth_ui_url}/settings`;
          if (externalSettingsWorkspaceItems && externalSettingsWorkspaceItems.externalLink !== newExternalLink) {
            externalSettingsWorkspaceItems.externalLink = newExternalLink;
          }
        }
        return actions.setNavigationForUserAccountSuccess({
          payload: {
            hasCompany: hasCompany,
            navigation: hasCompany
              ? userAccountWorkspace
              : userAccountWorkspace.filter(
                  (workspaceItem: WorkspaceItem) => workspaceItem.path !== 'settings/companymemberships'
                ),
          },
        });
      })
    )
  );

  setNavigationForUserAccountSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setNavigationForUserAccountSuccess),
      map((action) => action.payload),
      map((payload: { hasCompany: boolean; navigation: WorkspaceItem[] }) => payload.hasCompany),
      switchMap((hasCompany) => {
        if (hasCompany) {
          return EMPTY;
        }
        return of(actions.setWorkspaces());
      })
    )
  );

  setNavigationForCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setNavigationForCompany),
      switchMapTo(this.companiesFacade.getAllocations().pipe(take(1))),
      switchMap((allocations: CompanyAllocations) => {
        const accessibleProducerWorkspace$ = this.getAccessibleWorkspace(producerWorkspace).pipe(
          switchMap((workspaceItems: WorkspaceItem[]) => this.applyProducerSupportTicketUpdates(workspaceItems))
        );
        return combineLatest([
          this.getAccessibleWorkspace(companyWorkspace),
          this.getAccessibleWorkspace(academyWorkspace),
          allocations.hasShops ? this.getAccessibleWorkspace(shopOwnerWorkspace) : of([]),
          allocations.isPartner ? this.getAccessibleWorkspace(partnerWorkspace) : of([]),
          allocations.isProducer ? accessibleProducerWorkspace$ : of([]),
        ]).pipe(take(1));
      }),
      map(
        ([companyWsp, academyWsp, shopOwnerWsp, partnerWsp, producerWsp]: [
          WorkspaceItem[],
          WorkspaceItem[],
          WorkspaceItem[],
          WorkspaceItem[],
          WorkspaceItem[],
        ]) => {
          const navigation: Partial<NavigationState> = {};

          if (companyWsp.length > 0) {
            navigation.company = companyWsp;
          }
          if (academyWsp.length > 0) {
            navigation.academy = academyWsp;
          }
          if (shopOwnerWsp.length > 0) {
            navigation.shopOwner = shopOwnerWsp;
          }
          if (partnerWsp.length > 0) {
            navigation.partner = partnerWsp;
          }
          if (producerWsp.length > 0) {
            navigation.producer = producerWsp;
          }
          return actions.setNavigationForCompanySuccess({ payload: { navigation: navigation } });
        }
      )
    )
  );

  setNavigationForCompanySuccess$ = createEffect(() =>
    this.actions$.pipe(ofType(actions.setNavigationForCompanySuccess), mapTo(actions.setWorkspaces()))
  );

  setWorkspaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setWorkspaces),
      switchMapTo(
        combineLatest([
          this.store.select(selectors.hasPartnerWorkspace),
          this.store.select(selectors.hasProducerWorkspace),
          this.store.select(selectors.hasShopOwnerWorkspace),
          this.store.select(selectors.hasAcademyWorkspace),
          this.store.select(selectors.hasCompanyWorkspace),
          this.store.select(selectors.hasUserAccountWorkspace),
        ]).pipe(take(1))
      ),
      map(
        ([
          hasPartnerWorkspace,
          hasProducerWorkspace,
          hasShopOwnerWorkspace,
          hasAcademyWorkspace,
          hasCompanyWorkspace,
          hasUserAccountWorkspace,
        ]: [boolean, boolean, boolean, boolean, boolean, boolean]) => {
          const accessibleWorkspaces: Workspace[] = [];
          if (hasPartnerWorkspace) {
            accessibleWorkspaces.push(workspaces.find((workspace: Workspace) => 'partner' === workspace.name));
          }
          if (hasProducerWorkspace) {
            accessibleWorkspaces.push(workspaces.find((workspace: Workspace) => 'producer' === workspace.name));
          }
          if (hasShopOwnerWorkspace) {
            accessibleWorkspaces.push(workspaces.find((workspace: Workspace) => 'shopOwner' === workspace.name));
          }
          if (hasAcademyWorkspace) {
            accessibleWorkspaces.push(workspaces.find((workspace: Workspace) => 'academy' === workspace.name));
          }
          if (hasCompanyWorkspace) {
            accessibleWorkspaces.push(workspaces.find((workspace: Workspace) => 'company' === workspace.name));
          }
          if (hasUserAccountWorkspace) {
            accessibleWorkspaces.push(workspaces.find((workspace: Workspace) => 'userAccount' === workspace.name));
          }
          return accessibleWorkspaces;
        }
      ),
      map((accessibleWorkspaces: Workspace[]) =>
        actions.setWorkspacesSuccess({ payload: { workspaces: accessibleWorkspaces } })
      )
    )
  );

  private getAccessibleWorkspace(workspace: WorkspaceItem[]): Observable<WorkspaceItem[]> {
    const accessGiven = [];
    let numberItemsAlwaysVisible = 0;
    for (const workspaceItem of workspace) {
      accessGiven.push(this.aclFacade.isAccessGrantedForLocation(workspaceItem.path));
      if (workspaceItem.alwaysVisible) {
        numberItemsAlwaysVisible++;
      }
    }

    return combineLatest([...accessGiven]).pipe(
      map((hasAccessToRoute: boolean[]) => {
        let updatedWorkspace = [];
        for (const [i, hasAccess] of hasAccessToRoute.entries()) {
          if (hasAccess) {
            updatedWorkspace.push(workspace[i]);
          }
        }
        // clear if there are only items which are alwaysVisible
        if (numberItemsAlwaysVisible === updatedWorkspace.length) {
          updatedWorkspace = [];
        }

        return updatedWorkspace;
      })
    );
  }

  private applyProducerSupportTicketUpdates(workspaceItems: WorkspaceItem[]): Observable<WorkspaceItem[]> {
    const support = workspaceItems.find((workspaceItem: WorkspaceItem) => workspaceItem.path === 'producer/support');
    if (undefined === support) {
      return of(workspaceItems);
    }
    return this.companiesFacade.getSupportTicketUpdates().pipe(
      map((supportTicketUpdates: SupportTicketUpdates) => {
        if (support) {
          const indexSupport = workspaceItems.indexOf(support);
          const supportItem = { ...support };
          if (supportTicketUpdates?.producer?.open.length > 0 || supportTicketUpdates?.producer?.overdue.length > 0) {
            const ticketCount =
              supportTicketUpdates.producer.open.length + supportTicketUpdates.producer.overdue.length;
            const cssClasses = ['supportticketupdates'];
            if (supportTicketUpdates.producer.overdue.length > 0) {
              cssClasses.push('is-overdue ');
            }
            if (ticketCount > 9) {
              cssClasses.push('is-large-value');
            }
            supportItem.badge = {
              cssClasses: cssClasses.join(' '),
              value: ticketCount,
            };
          } else {
            supportItem.badge = undefined;
          }
          if (indexSupport !== -1) {
            workspaceItems[indexSupport] = supportItem;
          }
        }

        return workspaceItems;
      })
    );
  }
}
