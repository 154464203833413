import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ToastService } from '../../shared/components/toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class TooManyRequestsInterceptor implements HttpInterceptor {
  constructor(
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: Error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.error && error.status === 429) {
            this.toastService.error(
              this.translateService.instant('COMMON.TOAST.ERROR.MESSAGE.TOO_MANY_REQUESTS_TITLE'),
              this.translateService.instant('COMMON.TOAST.ERROR.MESSAGE.TOO_MANY_REQUESTS')
            );
          }
        }
        return throwError(error);
      })
    );
  }
}
