import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

// const extractedError = this.extractError(error) || 'Handled unknown error';
// const target = hint.event.target as HTMLElement;
// const chunkFailedMessage = /Loading chunk \d+\s?failed/;

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));

// if (environment.hmr) {
//   if (Object.prototype.hasOwnProperty.call(module, 'hot') && (module as Record<string, any>).hot) {
//     hmrBootstrap(module, bootstrap);
//   } else {
//     console.error('HMR is not enabled for webpack-dev-server!');
//     // eslint-disable-next-line no-console
//     console.log('Are you using the --hmr flag for ng serve?');
//   }
// } else {
//   // eslint-disable-next-line no-console
//   bootstrap().catch(error => console.log(error));
// }
