import { ApplicationRef, NgModuleRef } from '@angular/core';
import { createNewHosts } from '@angularclass/hmr';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { RootState } from './app/core/store/root.state';

export let appState: any = {};

export const hmrBootstrap = (module: any, bootstrap: () => Promise<NgModuleRef<any>>): void => {
  let ngModule: NgModuleRef<any>;
  module.hot.accept();
  bootstrap().then((mod) => (ngModule = mod));
  module.hot.dispose(() => {
    const appRef: ApplicationRef = ngModule.injector.get(ApplicationRef);
    const elements = appRef.components.map((c) => c.location.nativeElement);
    const store: Store<RootState> = ngModule.injector.get(Store) as any;
    store.pipe(take(1)).subscribe((s) => {
      appState = s;
    });
    const makeVisible = createNewHosts(elements);
    ngModule.destroy();
    makeVisible();
  });
};
