import { AuthFacade, LanguagesFacade } from '@account/core/facades';
import { HTTP_INTERCEPTORS, HttpClient, HttpErrorResponse, provideHttpClient } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  Injectable,
  InjectionToken,
} from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  provideRouter,
  RouterConfigOptions,
  RouterConfigurationFeature,
  withInMemoryScrolling,
  withRouterConfig,
} from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { ActionReducerMap, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { replayIntegration } from '@sentry/angular';
import * as Sentry from '@sentry/browser';
import { browserTracingIntegration } from '@sentry/browser';
import { Angulartics2Module } from 'angulartics2';
import { CookieService } from 'ngx-cookie-service';
import { provideMarkdown } from 'ngx-markdown';

import { environment } from '../environments/environment';
import { appState } from '../hmr';
import { appRoutes } from './app.routes';
import { onAppInit } from './core/app-init';
import { AcceptLanguageInterceptor } from './core/interceptors/accept-language.interceptor';
import { CorrelationIdInterceptor } from './core/interceptors/correlation-id.interceptor';
import { NoWritePermissionsInterceptor } from './core/interceptors/no-write-permissions.interceptor';
import { TooManyRequestsInterceptor } from './core/interceptors/too-many-requests.interceptor';
import { UserTokenExceptionInterceptor } from './core/interceptors/user-token-exception.interceptor';
import { AccountTableContainerIntl } from './core/intl/account-table-container-intl.service';
import {
  CaptchaService,
  EnvironmentService,
  FeatureFlagService,
  OrySessionService,
  TokenService,
} from './core/services';
import { metaReducers, reducers } from './core/store';
import { BasketEffects } from './core/store/basket/basket.effects';
import { AllocationsEffects } from './core/store/company/allocations/allocations.effects';
import { CompanyEffects } from './core/store/company/company/company.effects';
import { SignedGTCEffects } from './core/store/company/signed-gtc/signed-gtc.effects';
import { SupportPermissionsEffects as CompanySupportPermissionsEffects } from './core/store/company/support-permissions/support-permissions.effects';
import { SupportTicketUpdatesEffects } from './core/store/company/support-ticket-updates/support-ticket-updates.effects';
import { NavigationEffects } from './core/store/navigation/navigation.effects';
import { RootState } from './core/store/root.state';
import { SessionEffects } from './core/store/session/session.effects';
import { InvitationsEffects } from './core/store/useraccount/invitations/invitations.effects';
import { MembershipsEffects } from './core/store/useraccount/memberships/memberships.effects';
import { UseraccountEffects } from './core/store/useraccount/useraccount/useraccount.effects';
import { PruningTranslationLoader } from './core/translate/translation.loader';
import { SbpTableContainerIntl } from './shared/components/table-container/table-container-intl.service';

// AoT requires an exported function for factories
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function PruningTranslationLoaderFactory(http: HttpClient): PruningTranslationLoader {
  return new PruningTranslationLoader(http);
}

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<RootState>>('register reducers', {
  factory: () => reducers,
});

if (['live'].includes(environment.envName)) {
  Sentry.init({
    dsn: 'https://7082c3439fed4d0893fd9a82ebcb9b84@sentry.io/246196',
    debug: false,
    release: environment.version,
    environment: environment.envName,
    tracesSampleRate: 0.1,
    ignoreErrors: ['Non-Error promise rejection captured'],
    denyUrls: [new RegExp('.*/password-reset.*'), new RegExp('.*/verify(account)?.*')],
    // Capture 10% of all sessions
    replaysSessionSampleRate: 0.1,
    // Of the remaining 90% of sessions, if an error happens start capturing
    replaysOnErrorSampleRate: 1.0,
    integrations: (integrations) => [
      ...integrations,
      browserTracingIntegration(),
      replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    beforeBreadcrumb(breadcrumb: Sentry.Breadcrumb, hint: { [key: string]: any }): Sentry.Breadcrumb | null {
      switch (breadcrumb.category) {
        case 'ui.click': {
          const target = hint.event.target as HTMLElement;
          if (!target) {
            return null;
          } else if (-1 === ['a', 'button'].indexOf(target.localName)) {
            // do not log redundant clicks
            return null;
          }
          if (!target) {
            return null;
          }
          breadcrumb.message = `Clicked: \`${target.localName}\` with content: \`${target.innerHTML.trim()}\``;
          break;
        }
        case 'xhr': {
          // do not track assets calls
          if (breadcrumb.data.url.startsWith('/assets') && 200 === +breadcrumb.data.status_code) {
            return null;
          }
          break;
        }
        // skip default
      }

      return breadcrumb;
    },
    // If handled by NgModule's ErrorHandler do not handle the error again, see: https://github.com/getsentry/sentry-javascript/issues/2532
    beforeSend: (event, hint) => (hint?.originalException instanceof HttpErrorResponse ? null : event),
  });
}

export const matTooltipDefaultOptions: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 1500,
  disableTooltipInteractivity: true,
};

@Injectable({
  providedIn: 'root',
})
export class HammerConfig extends HammerGestureConfig {
  override options = {
    cssProps: {
      userSelect: true,
    },
  };
}

@Injectable({
  providedIn: 'root',
})
export class SentryErrorHandler implements ErrorHandler {
  extractError(error: any): string | Error | { error: any; message: string } {
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && error.ngOriginalError) {
      error = error.ngOriginalError;
    }

    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent && error.error.message) {
        return error.error.message;
      }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return {
        error: error.error,
        message: error?.message ?? '',
      };
    }

    if (
      error &&
      Object.prototype.hasOwnProperty.call(error, 'success') &&
      Object.prototype.hasOwnProperty.call(error, 'code') &&
      error.code === 'Exception-0'
    ) {
      return error;
    }

    // Skip if there's no error, or a proper error response like 400 is received by the API.
    return null;
  }

  handleError(error: any): void {
    const extractedError = this.extractError(error) || 'Handled unknown error';

    // see: https://stackoverflow.com/a/58197858/1173391
    const chunkFailedMessage = /Loading chunk \d+\s?failed/;
    if (error.message && chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }

    // Capture handled exception and send it to Sentry.
    switch (environment.envName) {
      case 'live':
        this.captureError(extractedError);
        break;
      case 'next':
        this.captureError(extractedError, true);
        break;
      default:
        this.captureError(extractedError, false);
    }

    // Optionally show user dialog to provide details on what happened.
    // const eventId = Sentry.captureException(extractedError);
    // Sentry.showReportDialog({ eventId });
  }

  captureError(error: any, notifySentry = true): void {
    if (notifySentry) {
      Sentry.captureException(error);
    }
    console.error(error);
  }
}

export const translateModule = TranslateModule.forRoot({
  loader: {
    provide: TranslateLoader,
    useFactory: PruningTranslationLoaderFactory,
    deps: [HttpClient],
  },
});

const isInDevMode = environment.envName !== 'live';

const routerConfig: RouterConfigOptions = {
  onSameUrlNavigation: 'reload',
};

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};

const routerConfigFeature: RouterConfigurationFeature = withRouterConfig(routerConfig);
const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(),
    provideRouter(appRoutes, ...[inMemoryScrollingFeature, routerConfigFeature]),
    provideAnimations(),
    provideStore(REDUCER_TOKEN, { initialState: appState, metaReducers: metaReducers }),
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.envName === 'live', // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      connectInZone: true, // Attempts to use ngZone to run the dispatches, to ensure changes are detected
      trace: isInDevMode, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
    }),
    provideEffects([
      SessionEffects,
      AllocationsEffects,
      CompanyEffects,
      SupportTicketUpdatesEffects,
      CompanySupportPermissionsEffects,
      SignedGTCEffects,
      InvitationsEffects,
      MembershipsEffects,
      UseraccountEffects,
      NavigationEffects,
      BasketEffects,
    ]),
    importProvidersFrom(
      BrowserModule,
      HammerModule,
      BrowserAnimationsModule,
      CaptchaService,
      Angulartics2Module.forRoot({ developerMode: isInDevMode }),
      translateModule
    ),
    [
      CookieService,
      {
        provide: ErrorHandler,
        useClass: SentryErrorHandler,
      },
      {
        provide: HAMMER_GESTURE_CONFIG,
        useClass: HammerConfig,
      },
      {
        provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
        useValue: matTooltipDefaultOptions,
      },

      {
        provide: APP_INITIALIZER,
        useFactory: onAppInit,
        multi: true,
        deps: [EnvironmentService, TokenService, OrySessionService, AuthFacade, LanguagesFacade, FeatureFlagService],
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: UserTokenExceptionInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: CorrelationIdInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AcceptLanguageInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: TooManyRequestsInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: NoWritePermissionsInterceptor,
        multi: true,
      },
      {
        provide: SbpTableContainerIntl,
        useClass: AccountTableContainerIntl,
      },
    ],
    provideMarkdown(),
  ],
};

// [

//   // analytics
//
//   // Service worker (CURRENTLY NOT ENABLED)
//   ServiceWorkerModule.register('/ngsw-worker.js', { enabled: false }),
//   // must be the last module
//   CoreModule),

// ]
